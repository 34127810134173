import React, {useEffect, useRef, useState} from 'react';
import classes from './Filter.module.css';
import dropdown from './../../img/dropdown.svg';
import cross from './../../img/Cross.svg';

function debounce(fn, delay) {
	let timer;
	return function () {
		clearTimeout(timer);
		timer = setTimeout(() => {
			fn.apply(this, arguments);
		}, delay);
	};
}

const Filter = ({handleSearch, handleFilterData, query}) => {
	const [showYearFilter, setIsShowYearFilter] = useState(false);
	const [showRatingFilter, setIsRatingGenreFilter] = useState(false);
	const [checkInputChecked, setIscheckInputChecked] = useState(false);
	const [showCross, setIsShowCross] = useState(false);
	const [showRatingCross, setIsRatingShowCross] = useState(false);
	const [showGenreCross, setIsGenreShowCross] = useState(false);
	const [years, setYears] = useState([]);
	const [genres, setGenres] = useState([]);
	const [rating, setRating] = useState(null);
	const [sortBy, setSortBy] = useState('');
	const ref = useRef([]);
	const ref2 = useRef([]);
	const ref3 = useRef([]);
	useEffect(() => handleFilterData({year: years, genre: genres, rating, sortby: sortBy}), [years, genres, rating, sortBy]);

	/**YEARS */
	const initialYears = [
		{id: '2024', label: '2024', checked: false},
		{id: '2023', label: '2023', checked: false},
		{id: '2022', label: '2022', checked: false},
		{id: '2021', label: '2021', checked: false},
		{id: '2016-2020', label: '2016-2020', checked: false},
		{id: '2011-2015', label: '2011-2015', checked: false},
		{id: '2001-2010', label: '2001-2010', checked: false},
	];
	const [selectedYears, setSelectedYears] = useState(initialYears);
	const [localQuery, setLocalQuery] = useState(query);
	const handleYearsClicked = (id) => {
		setSelectedYears((prevCheckboxes) => prevCheckboxes.map((checkbox) => (checkbox.id === id ? {...checkbox, checked: !checkbox.checked} : checkbox)));
	};

	useEffect(() => {
		setYears(selectedYears.filter((x) => x.checked).map((t) => t.label));
		const active = selectedYears.find((i) => i.checked);
		if (active) {
			setIsShowCross(true);
		}
		else {
			setIsShowCross(false);
		}
	}, [showCross, selectedYears]);

	const Unchecked = () => {
		setIsShowCross(false);

		for (let i = 0; i < selectedYears.length; i++) {
			selectedYears[i].checked = false;
		}
		setYears([]);
	};

	/**GENRES */
	const initialGenres = [
		{id: 'Action', label: 'Action', value: '1', checked: false},
		{id: 'Adventure', label: 'Adventure', value: '2', checked: false},
		{id: 'Soup Opera', label: 'Soup Opera', value: '11', checked: false},
		{id: 'Drama', label: 'Drama', value: '4', checked: false},
		{id: 'History', label: 'History', value: '5', checked: false},
		{id: 'Comedy', label: 'Comedy', value: '6', checked: false},
		{id: 'Biography', label: 'Biography', value: '7', checked: false},
		{id: 'Romance', label: 'Romance', value: '8', checked: false},
		{id: 'Thriller', label: 'Thriller', value: '9', checked: false},
		{id: 'Horror', label: 'Horror', value: '10', checked: false},
	];

	const [selectedGenres, setSelectedGenres] = useState(initialGenres);

	const handleGenresClicked = (id) => {
		setSelectedGenres((prevCheckboxes) => prevCheckboxes.map((checkbox) => (checkbox.id === id ? {...checkbox, checked: !checkbox.checked} : checkbox)));
	};

	const handleInputChange = (e) => {
		setLocalQuery(e.target.value);
	};


	useEffect(() => {
		const timerId = setTimeout(() => {
			handleSearch(localQuery);
		}, 400);

		return () => {
			clearTimeout(timerId);
		};
	}, [localQuery, handleSearch]);

	useEffect(() => {
		setGenres(selectedGenres.filter((x) => x.checked).map((t) => t.value));
		const active = selectedGenres.find((i) => i.checked);
		if (active) {
			setIsGenreShowCross(true);
		}
		else {
			setIsGenreShowCross(false);
		}
	}, [showGenreCross, selectedGenres]);

	const checkboxUnCheckGenre = () => {
		setIsGenreShowCross(false);
		for (let i = 0; i < selectedGenres.length; i++) {
			selectedGenres[i].checked = false;
		}
		setGenres([]);
	};


	const showYearFilterHandler = () => {
		setIsShowYearFilter((prevCheck) => !prevCheck);
		setIsRatingGenreFilter(false);
		setIscheckInputChecked(false);
	};

	const showRatingFilterHandler = () => {
		setIsRatingGenreFilter((prevCheck) => !prevCheck);
		setIsShowYearFilter(false);
		setIscheckInputChecked(false);
	};

	const showGenreFilterHandler = () => {
		setIscheckInputChecked((prevCheck) => !prevCheck);
		setIsShowYearFilter(false);
		setIsRatingGenreFilter(false);
	};

	const showAzHandler = () => {
		if (sortBy === '') {
			setSortBy('A-Z');
		}
		else
		if (sortBy === 'A-Z') {
			setSortBy('Z-A');
		}
		else {
			setSortBy('A-Z');
		}
	};

	return (
		<div className={classes.filterTop}>
			<form>
				<input type="text" placeholder="Series,actors" name="search" onChange={handleInputChange} value={query}/>
			</form>
			<div className={classes.filterData}>
				<div className={showYearFilter ? `${classes.byYear} ${classes.active}` : `${classes.byYear}`} onClick={showYearFilterHandler}>
					<p>Year</p> <img src={dropdown} className={classes.dropdownBtn} alt="dropdown"/>
					{showCross && <img className={classes.cross} onClick={Unchecked} src={cross} alt="cross"/>}
				</div>
				<div className={checkInputChecked ? `${classes.genre} ${classes.genreActive}` : `${classes.genre}`} onClick={showGenreFilterHandler}>
					<p>Genre</p> <img src={dropdown} className={classes.dropdownBtn} alt="dropdown"/>
					{showGenreCross && <img className={classes.cross} onClick={checkboxUnCheckGenre} src={cross} alt="cross"/>}
				</div>
				<div className={sortBy === 'A-Z' ? `${classes.genre} ${classes.genreActive}` : `${classes.genre}`} onClick={showAzHandler}>
					<img src={dropdown} className={classes.dropdownBtn} alt="dropdown"/> <p style={{paddingLeft: 23}}>{!sortBy ? 'A-Z' : sortBy }</p>
				</div>
			</div>
			<div className={classes.showData}>
				{showYearFilter && (
					<div className={classes.yeaName}>
						{selectedYears.map((year) => (
							<div className={classes.newCheckbox}>
								<label key={year.id}>
									<input type="checkbox" name={year.id} checked={year.checked} onChange={() => handleYearsClicked(year.id)}/>
									<p>{year.label}</p>
								</label>
							</div>
						))}
					</div>
				)}


				{checkInputChecked && (
					<div className={classes.yeaName}>
						{selectedGenres.map((genre) => (
							<div className={classes.newCheckbox}>
								<label key={genre.id}>
									<input type="checkbox" name={genre.id} checked={genre.checked} onChange={() => handleGenresClicked(genre.id)}/>
									<p>{genre.label}</p>
								</label>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default Filter;
